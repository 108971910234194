html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Blackwing";
  src: local("Blackwing"), url(./fonts/Blackwing.ttf) format("truetype");

}

@font-face {
  font-family: "Edwardian";
  src: local("Edwardian"), url(./fonts/ITCEDSCR.TTF) format("truetype");
}

@font-face {
  font-family: "ZapfinoForteLTPro";
  src: local("ZapfinoForteLTPro"), url(./fonts/ZapfinoForteLTPro.otf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, "Times New Roman", Georgia, Serif, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  /*background-color: rgba(248, 227, 195, 0.66);*/
  background-color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Times New Roman",
    monospace;
}

/* the hover and element colors of the texts in the upper navigation bar*/
.upper_nav_element {
  color: #fff;
  padding-right: 20px;
  padding-left: 20px;
}

.upper_nav_element:hover,
.upper_right_nav_element:hover {
  color: lightgray;
}

.home_button {
  color: white;
  float: right;
  font-size: 20px;
}

.home_button:hover {
  color: white;
  text-decoration: none;
}

.upper_right_nav_element {
  color: #fff;
  float: right;
  padding-left: 20px;
}

/*
.active {
  background-color: #e645db;
  color: white;
}
*/

.right_margin_20 {
  padding-top: 20px;
  padding-left: 20px;
}


.title_hd {
  color: black;
  text-align: center;
  font-family: Edwardian, serif;
  font-size: 4em;
}


.HomePageCompanyName {
  color: black;
  text-align: center;
}

.title_pm {
  color: black;
  text-align: center;
  font-family: ZapfinoForteLTPro, serif;
  font-size: 4em;
}

.lighter_detail {
  color: gray;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.bottomTopPadding {
  margin-bottom: 20px;
  margin-top: 20px;
}

.sidePadding {
  margin-left: 20px;
  margin-right: 20px;
}

.black_button {
  position: relative;
  display: inline-block;
  padding: 0.25em 0.5em;
  text-decoration: none;
  color: #fff;
  background: #1a0b07; /*button color*/
  border-bottom: solid 2px #010000; /*darker color*/
  border-radius: 4px;
  box-shadow: inset 0 2px 0 rgba(43, 43, 43, 0.2), 0 2px 2px rgba(0, 0, 0, 0.19);
  font-weight: bold;
}

.black_button:active {
  border-bottom: solid 2px #1a0b07;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

.centering {
  display: flex;
  justify-content: center;
}

.buy-buttons {
  color: white;
  background-color: white;
}

.right_alignment {
  text-align: right;
}
.left_alignment {
  /*text-align: left;*/
  margin-right: 20px;
}

.left_alignment_margin {
  text-align: left;
  margin-right: 50px;
  margin-left: 40px;
}
.padding_large {
  margin-top: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
}

.navbar {
  background-color: rgba(176, 87, 63, 0.66);
  color: white;
}
.white {
  color: white;
}

br {
  margin-right: 10px;
  padding-right: 10px;
  background-color: gold;
}

.about_pic {
  margin-top: 50px;
  height: auto;
  width: 100%;
}

.about_pic_larger {
  margin-top: 50px;
  height: auto;
  width: 113%;
}

/*
@media only screen and (max-width: 768px) {
    /* For mobile phones:
    [class*="about_pic"] {
        width: 80%;
    }
}*/

.image_half {
  width: 50%;
}

.image_third {
  width: 30%;
}

.padding_top {
  margin-top: 50px;
}
.text_margin_left {
  margin-left: 50px;
  margin-right: 50px;
}
.white_text {
  color: white;
}

/*background-color: rgba(151, 124, 75, 0.678);*/
.padding_large_background {
  background-color: rgba(148, 126, 86, 0.678);
  border: 5px solid rgba(0, 0, 0, 0.5);
}

.padding_large_smaller {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.padding_top {
  margin-top: 50px;
}

.white_text {
  color: white;
}

.black_border {
  border: 5px solid white;
}

.white_title_centering {
  color: white;
  display: flex;
  justify-content: center;
  font-size: x-large;
}

.margin_left {
  margin-left: 1em;
}

.margin_right {
  margin-right: 1em;
}

.left_right_margin {
  width: 90%;
  margin-left: 2.5em;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
}

.left_right_margin_large {
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  width: 50em;
}

.contact_card {
  border-radius: 25px;
  background: #f8f4ed;
  margin-left: 0.5em;
  margin-right: 0.5em;
  border: 5px solid rgba(0, 0, 0, 0.315);
}

.midsize_font {
  font-size: 20px;
}

.padding_top_mid {
  margin-top: 1em;
}

.main_pic {
  width: 50%;
}

.middle_page_pm {
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  width: 30em;
}

.margin_1em {
  margin-left: 1em;
  margin-right: 1em;
}
