.White {
  text-align: center;
  background-color: #ffffffff;
  /* 2 underneath make sure there are no white lines outside of the website */
  overflow: hidden;
  box-sizing: border-box;
}

.arrowL{
  margin-top: 60px;

}
.arrowR{
  margin-top: 60px;
}
.arrowR_large{
  margin-top: 200px;
}
.arrowL_large{
  margin-top: 200px;
}